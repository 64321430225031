$(window).load(function () {
  const { matches: isDesktop } = window.matchMedia('(min-width: 1024px)');
  let heroTheme = $('#page-hero').data('hero-theme') ?? null;

  checkCookie();

  function displayNavBackground() {
    $('.newnav-container').addClass('newnav-container-background');
    $('.newnav-category').addClass('newnav-category-light');
    $('.newnav-category').removeClass('newnav-category-dark');
    $('#josh-logo-lighttheme').removeClass('transparent');
    $('#josh-logo-darktheme').addClass('transparent');
  }

  function displayNavLightTheme() {
    $('.newnav-container').removeClass('newnav-container-background');
    $('.newnav-category').addClass('newnav-category-light');
    $('.newnav-category').removeClass('newnav-category-dark');
    $('#josh-logo-lighttheme').removeClass('transparent');
    $('#josh-logo-darktheme').addClass('transparent');
  }

  function displayNavDarkTheme() {
    $('.newnav-container').removeClass('newnav-container-background');
    $('.newnav-category').addClass('newnav-category-dark');
    $('.newnav-category').removeClass('newnav-category-light');
    $('#josh-logo-lighttheme').addClass('transparent');
    $('#josh-logo-darktheme').removeClass('transparent');
  }

  function XtoHam() {
    $('.hamburger').removeClass('x');
    $('.burgerLine1').css('transform', 'rotate(0deg)').css('margin-top', '4px');
    $('.burgerLine2')
      .css('transform', 'rotate(0deg)')
      .css('margin-top', '12px');
    $('.burgerLine3').fadeIn(0).css('opacity', '1').css('margin-top', '20px');
  }

  function hamToX() {
    $('.hamburger').addClass('x');
    $('.burgerLine1')
      .css('transform', 'rotate(45deg)')
      .css('margin-top', '12px');
    $('.burgerLine2')
      .css('transform', 'rotate(315deg)')
      .css('margin-top', '12px');
    $('.burgerLine3').hide(0).css('opacity', '0');
  }

  function expandMobileNav() {
    hamToX();

    // open the nav to full screen
    $('#main-nav').addClass('expandedNav');

    // disable scrolling
    $('html').css('overflow', 'hidden');

    //reset mobile dropdowns
    $('.sub-links').css('display', 'none');
    $('.nav-arrow').removeClass('nav-up');
    $('.nav-arrow').addClass('nav-down');
  }

  function shrinkMobileNav() {
    XtoHam();

    // close full screen nav
    $('#main-nav').removeClass('expandedNav');

    // re-enable scrolling
    $('html').css('overflow', 'unset');

    // page is not scrolled if true
    if (!$('.links').hasClass('scrolled')) {
      $('.links').addClass('initial');
    }

    // reset mobile dropdowns
    $('.sub-links').css('display', 'none');
    $('.nav-arrow').removeClass('nav-up');
    $('.nav-arrow').addClass('nav-down');
  }

  if (isDesktop) {
    if (heroTheme === 'dark') {
      displayNavDarkTheme();
    } else if (heroTheme === 'light') {
      displayNavLightTheme();
    } else {
      displayNavBackground();
    }
    $('.newnav-container').css('opacity', 1);

    $('.newnav-container').hover(() => {
      displayNavBackground();
    });

    $('.newnav-container').mouseleave(() => {
      if ($('#page-hero')?.get(0)?.getBoundingClientRect()?.bottom > 80) {
        if (heroTheme === 'dark') {
          displayNavDarkTheme();
        } else {
          displayNavLightTheme();
        }
      } else {
        displayNavBackground();
      }
    });
  } else {
    ///////////////
    // IS MOBILE //
    ///////////////

    $('#mobile-nav-hamburger').click(function () {
      if (!$('#main-nav').hasClass('expandedNav')) {
        expandMobileNav();
      } else {
        shrinkMobileNav();
      }
    });

    $('.newnav-mobile-category-row').each(function () {
      $(this).click(function (e) {
        if (e.target.href === undefined) {
          e.preventDefault();
          let section = $(this).data('section');
          let subLinks = '.' + section + '-category-links';
          let arrow = '#' + section + '-arrow';

          if ($(arrow).hasClass('nav-up')) {
            $(subLinks).css('display', 'none');
            $(arrow).removeClass('nav-up');
            $(arrow).addClass('nav-down');
          } else {
            $(subLinks).css('display', 'flex');
            $(arrow).removeClass('nav-down');
            $(arrow).addClass('nav-up');
          }
        }
      });
    });

    // $('#newbase-content').click(function () {
    //   // animate out/hide expandedNav
    //   shrinkNav();

    //   // reenable scrolling
    //   $('html').css('overflow', 'unset');
    //   // ANIMATE NAV "X" TO MENU ICON
    //   XtoHam();
    // });
  }

  //INITIAL
  $('.hamburger').css('opacity', '.8');
  //Generate light or dark for backgrounds
  // $('.nav-target').each(function () {
  //   $(this).colourBrightness();
  // });

  // MOBILE TWEAKS
  // if (!isDesktop) {
  // $('.dog-logo.dark-text').removeClass('faded');
  // $('.dog-logo.dark-text')
  //   .removeClass('transparent')
  //   .addClass('current-logo');
  // $('.dog-logo.white-text, .dog-logo.white-head, .dog-logo.dark-head')
  //   .addClass('transparent')
  //   .removeClass('current-logo');
  // }

  // CLICK ACTIONS
  //open nav on mobile, click hamburger
  // $('#mobile-nav-hamburger').click(function () {
  //   if (!$('#main-nav').hasClass('expandedNav')) {
  //     console.log("expanded")
  //   } else {
  //     console.log("not expanded")
  //   }

  // if ($('#main-nav').hasClass('expandedNav')) {
  //   // animate out/hide expandedNav
  //   shrinkNav();

  //   // reenable scrolling
  //   $('html').css('overflow', 'unset');
  //   // ANIMATE NAV "X" TO MENU ICON
  //   XtoHam();
  // } else {
  //   // animate in and show expandedNav
  //   expandNav();

  //   // DISABLE SCROLLING
  //   $('html').css('overflow', 'hidden');
  //   // Menu to X
  //   hamToX();
  // }
  // });
  // END CLICK ACTIONS

  //CLICK OUT TO CLOSE ON MOBILE
  // if (!isDesktop) {
  //   $('#newbase-content').click(function () {
  //     // animate out/hide expandedNav
  //     shrinkNav();

  //     // reenable scrolling
  //     $('html').css('overflow', 'unset');
  //     // ANIMATE NAV "X" TO MENU ICON
  //     XtoHam();
  //   });
  // }

  //SCROLL ACTIONS
  $(document).ready(function () {
    $(window).scroll(function () {
      //mobile

      if (isDesktop) {
        if ($('#page-hero')?.get(0)?.getBoundingClientRect()?.bottom > 80) {
          if (heroTheme === 'dark') {
            displayNavDarkTheme();
          } else {
            displayNavLightTheme();
          }
        } else {
          displayNavBackground();
        }
      }
    });
    //END SCROLL ACTIONS
  });

  //ESCAPE KEY CLOSE EVENT
  $(document).keyup(function (e) {
    if (e.keyCode == 27) {
      //27 = Escape key
      //close main nav
      shrinkMobileNav();
    }
  });
  //END ESCAPE KEY CLOSE EVENT

  //Cookies
  $('.cookie-close').on('click', function (e) {
    $('.cookie-banner').addClass('no-show');
    setCookie('banner', 'closed');
  });

  function setCookie(cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  function getCookie(cname) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  function checkCookie() {
    var bannerClosed = getCookie('banner');
    if (bannerClosed == 'closed') {
      //don't show banner
      $('.cookie-banner').addClass('no-show');
    } else {
      //show banner
      $('.cookie-banner').removeClass('no-show');
    }
  }
});
